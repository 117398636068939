@import '../../styles/customMediaQueries.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100vh;
  width: 350px;
  padding: var(--modalPadding);
  display: block;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--colorWhite);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
    display: none;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
    display: block;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 0px;

  @media (--viewportLarge) {
    display: none;
  }
}

.heading {
  /* Font */
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  composes: p from global;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--colorGrey700);
  font-weight: 600;
  padding: 0;
}

.originalPrice {
  composes: p from global;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: var(--fontWeightBold);
  font-weight: 400;
  letter-spacing: 2px;
  margin: 0 0 0 24px;
  text-decoration: line-through;
  text-transform: uppercase;

  color: var(--colorGrey400);
  margin: 0;
  padding: 0;
  padding-left: 5px;

}

.originalPriceInfo {
  composes: p from global;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: var(--fontWeightBold);
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: var(--colorGrey400);

  margin: 0;
  padding: 0;

}

.originalPriceInfoSeperator {
  composes: p from global;
  font-family: Avenir LT Pro Black, sans-serif;

  color: var(--colorGrey700);

  margin: 0;
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;

}

.perUnit {
  /* Font */
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {}
}

.author {
  font-family: 'Century Gothic', sans-serif;
  font-size: 14px;
  composes: p from global;
  margin: 0px 24px 32px 0;
  color: var(--colorGrey700);

  display: flex;
  flex-direction: row;
  align-items: center;

  @media (--viewportMedium) {
    width: 100%;
    margin: 0px 0 15px 0;
  }
}

.providerAvatar {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    margin-right: 10px;
  }
}

.providerNamePlain {
  display: inline;

  @media (--viewportLarge) {
    display: inline;
  }
}

.providerNameLinked {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.orderHeading {
  display: block;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--colorGrey700);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  display: none;
  height: 100px;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    flex-direction: row;
    margin-right: unset;
  }
}

.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}

.originalPriceValue {
  /* Font */
  composes: h3 from global;
  color: black;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
  text-decoration: line-through;

  margin-top: 0;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.priceValue {
  /* Font */
  composes: h3 from global;
  color: black;
  font-family: Roboto, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;

  margin-top: 0;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.closedListingButton {
  border-left: 1px solid var(--colorGrey100);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.error {
  composes: h4 from global;
  color: var(--colorFail);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.errorSidebar {
  color: var(--colorFail);
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.fixedPosition {
  position: static;
  top: 0;
  right: 0;
  bottom: 0;
}

.fixedContainer {
  width: 100%;
  margin: 0 auto;
}

.brandName {
  font-weight: 600;
  font-size: 16px;
  color: grey;
  margin-top: 0px;
  margin-bottom: 15px;
}

.submitButton {
  border-color: var(--marketplaceColor);
}

.authorDescription {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
}

.sizeInfo {
  composes: p from global;
  font-style: normal;
  text-transform: uppercase;

  color: var(--colorGrey700);
  margin: 0 0 0 24px;

  @media (--viewportMedium) {
    margin: 0 0 0 0;
    padding: 0;
  }
}

.sizeDescription {
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0px;
  color: var(--marketplaceColor);
  margin-top: 0px;
}

.variantWrapper {
  margin-top: 24px;
  margin-bottom: 24px;

  & .variantBlock {

    & .mainVariantWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 10px;

      & .mainVariant {
        border: solid 2px var(--colorGrey100);
        background-color: var(--colorWhite);
        border-radius: var(--borderRadiusMedium);
        padding: 12px 16px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        align-self: flex-start;
        cursor: pointer;
        font-size: 14px;
        font-weight: var(--fontWeightRegular);
        font-family: 'Century Gothic', sans-serif;
        line-height: 100%;

        &.selectedVariant {
          border-color: var(--marketplaceColor);
        }

        &.disabledVariant {
          background: var(--colorGrey50);
          color: var(--colorGrey200);
          width: fit-content;
          cursor: not-allowed;
        }
      }
    }

    & .subVariant {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 12px;

      & .variant {
        border: solid 2px var(--colorGrey100);
        background-color: var(--colorWhite);
        border-radius: var(--borderRadiusMedium);
        padding: 12px 16px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        align-self: flex-start;
        cursor: pointer;
        font-size: 14px;
        font-weight: var(--fontWeightRegular);
        font-family: 'Century Gothic', sans-serif;
        line-height: 100%;

        &.selectedVariant {
          border-color: var(--marketplaceColor);
        }

        &.disabledVariant {
          background: var(--colorGrey50);
          color: var(--colorGrey200);
          width: fit-content;
          cursor: not-allowed;
        }
      }
    }
  }
}

.conditionLabel {
  padding: 10px 0px 0px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
}

.sizeLabel {
  padding: 0 0px 10px 0;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
}

.infoIcon {
  margin-top: 11px;
  margin-left: 5px;
  position: relative;

  @media (max-width: 768px) {
    margin-top: 3px;
  }
}

.iconInfoBox {
  position: absolute;
  z-index: 2;
  top: -50%;
  left: 120%;
  transform: translateX(5%);
  max-width: 400px;
  min-width: 250px;
  background-color: var(--colorGrey700);
  border: 1px solid var(--colorGrey);
  border-radius: var(--borderRadiusMedium);
  padding: 12px;
  box-shadow: var(--boxShadowPopup);
  display: none;
}

.infoIcon:hover .iconInfoBox {
  display: block;
}

.iconInfoBox ul {
  padding-left: 20px;
  color: var(--colorWhite);
  font-family: 'Century Gothic', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
}

.iconInfoBox li {
  margin-bottom: 10px;
  color: var(--colorWhite);
  font-family: 'Century Gothic', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
}

.iconInfoBox strong {
  font-weight: 600;
  color: var(--colorWhite);
  font-family: 'Century Gothic', sans-serif;
  font-style: normal;
  font-size: 13px;
}

.conditionLabelWrapper {
  display: flex;
  align-items: center;
}