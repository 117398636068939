@import '../../styles/customMediaQueries.css';

.root {
  position: absolute;
  top: 0;
  
  @media (--viewportMedium) {
    position: static;
  }
}

.settingsButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 3px;
  font-weight: var(--fontWeightRegular);
  font-family: Roboto, sans-serif;
  white-space: nowrap;
  
  padding: 30px 0;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.settingsLanguage {
  text-transform: uppercase;
}

.settingsSeparator {
  display: inline-block;
  width: 1px;
  height: 14px;
  background-color: #D0D5DD;
  margin: 0 10px;
}

.settingsCurrency {
  margin-right: 10px;
}


.modalRoot {
  composes: marketplaceModalBaseStyles from global;
  min-height: 100vh;
  height: 100%;

  padding: 20px;
  border-radius: 0;

  @media (--viewportMedium) {
    flex-basis: 600px;
    min-height: auto;
    height: auto;
  }
}

.form {

}

.formTitle {
  margin: 0 0 40px;
  padding: 0;
  font-size: 20px;
  line-height: 25px;
  color: var(--colorBlack);
}

.fieldsWrapper {
  margin-bottom: 60px;

  @media (--viewportSmall) {
    display: flex;
    justify-content: space-between;
  }
}

.field {
  width: 100%;
  margin: 0 0 10px;

  @media (--viewportSmall) {
    margin: 0;
  }

  & label {
    padding: 0 0 6px;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
  }

  & select {
    border-radius: 0;
    min-height: 44px;
    padding: 5px 32px 5px 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.5L6 6.5L11 1.5' stroke='%23667085' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-size: 12px 8px;
    border-color: #D0D5DD;

    &:hover,
    &:focus {
      box-shadow: none;
      outline: none;
      border-color: #D0D5DD;
      cursor: pointer;
    }
  }
}

.fieldSeparator {
  margin: 0 8px;
}

.buttonsWrapper {
  
  @media (--viewportSmall) {
    display: flex;
    justify-content: space-between;
  }
}

.button {
  margin: 0 0 10px;
  min-height: 44px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
  font-weight: var(--fontWeightSemmiBold);
  border-radius: 0;
  transition: all .3s;

  @media (--viewportSmall) {
    width: calc(50% - 6px);
  }
}

.buttonCancel {
  border: 1px solid #D0D5DD;
  color: #344054;
  background-color: var(--colorWhite);

  &:hover,
  &:focus {
    border: 1px solid var(--colorBlack);
    color: var(--colorWhite);
    background-color: var(--colorBlack);
    box-shadow: none;
  }
}

.buttonSave {
  color: var(--colorWhite);
  border: 1px solid var(--colorBlack);
  background-color: var(--colorBlack);

  &:hover,
  &:focus {
    border: 1px solid #D0D5DD;
    color: #344054;
    background-color: var(--colorWhite);
    box-shadow: none;
  }
}

